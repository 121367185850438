import React from "react";
import Container from "../container";

const ContactSection = () => {
  return (
    <Container>
      <div id="contact">
        <div className="border-t-2 max-w-xs md:max-w-sm lg:max-w-lg border-primary mx-auto"></div>   
        <section className="pt-8 lg:pt-16 pb-12 lg:pb-24">
            <h2 className="text-center text-light font-semibold tracking-wide uppercase mb-5">
                Kontakt
            </h2>
            <div className="text-4xl lg:text-4xl xl:text-5xl font-bold text-center pb-3 lg:pb-5">Noch Fragen?</div> 
            <div className="text-2xl lg:text-2xl xl:text-3xl font-bold text-center">Zögern Sie nicht uns zu kontaktieren.</div> 
            <div className="flex items-center flex-col">           
              <form className="flex w-full max-w-lg space-x-3">
                <div className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-white rounded-lg shadow dark:bg-gray-800">
                    <div className="mb-6 text-2xl text-center text-gray-800 dark:text-white">
                        Wir kümmern uns darum.
                    </div>
                    <div className="grid max-w-xl grid-cols-1 gap-4 m-auto lg:mx-5">
                        <div>
                            <div className=" relative ">
                                <input type="text" id="contact-form-name" className="rounded border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-300 focus:border-transparent" placeholder="E-Mail Adresse"/>
                                </div>
                            </div>
                            <div>
                              <div className=" relative ">
                                  <input type="text" id="contact-form-email" className="rounded border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-300 focus:border-transparent" placeholder="Betreff"/>
                                  </div>
                              </div>
                              <div>
                                  <label className="text-gray-700" for="name">
                                      <textarea className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded text-base focus:outline-none focus:ring-2 focus:ring-primary-300 focus:border-transparent" id="comment" placeholder="Geben Sie Ihre Nachricht hier ein..." name="comment" rows="5" cols="40">
                                      </textarea>
                                  </label>
                              </div>
                              <div className="text-right">
                                  <button type="submit" className="py-2 px-4  bg-primary hover:bg-primary-300 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow focus:outline-none rounded-lg">
                                      Senden
                                  </button>
                              </div>
                          </div>
                      </div>
                </form>
            </div>
        </section>
      </div>
    </Container>
  );
}

export default ContactSection;