import React from "react";

import Button from "../button";
import LogoText from "../../images/logo_text.png";
import AppStoreButton from "../appStoreButton";
import GooglePlayButton from "../googlePlayButton";

import HeroImage from '../../images/heroImage';
import Container from "../container";

const HeroSection = () => {
  return (
    <section className="pt-5 lg:pt-28 pb-5 lg:pb-16 bg-gradient-to-l from-primary to-light">
      <Container>
        <div className="container mx-auto px-8 lg:flex text-white">
          <div className="text-center lg:text-left lg:w-1/2 my-6 lg:my-28">
            <div className="w-60 my-6 mx-auto lg:mx-0 hidden">
              <img src={LogoText} alt="ViSpecs Logo" />
            </div>
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Ihr digitaler Optiker
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Eine personalisierte Vorabberatung für Ihr optisches Anliegen.
            </p>
            <p className="mt-8 md:mt-12 table mx-auto md:block xl:block">
              <div className="md:float-left lg:float-none xl:float-left mb-1 xl:mb-0 xl:mr-1"><AppStoreButton link="https://apps.apple.com/at/app/vispecs/id1508489528" target="_blank" /></div>
              <div><GooglePlayButton link="https://play.google.com/store/apps/details?id=com.vispecs.app&gl=AT" target="_blank" /></div>
            </p>
            <p className="mt-4 text-gray-600"></p>
          </div>
          <div className="lg:w-1/2 text-center px-5 lg:px-16">
            <HeroImage />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default HeroSection;