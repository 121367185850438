import React from "react";

import GuyWithGlasses from "../../images/guy_with_glasses.jpg";

const InfoSection = () => {
  return (
    <section className="bg-gray-100 pt-12 lg:pt-20 pb-12 lg:pb-24">
      <h2 className="text-center text-light font-semibold tracking-wide uppercase mb-5">
          Funktionen
      </h2>
      <div className="text-4xl lg:text-4xl xl:text-5xl font-bold text-center lg:pb-12">Warum ViSpecs?</div>        
      <div className="relative dark:bg-gray-800 p-4">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div className="lg:col-start-2 md:px-20">
                  <h4 className="text-2xl leading-8 font-extrabold text-gray-900 dark:text-white tracking-tight sm:leading-9 text-center">
                      Key Features auf einem Blick.
                  </h4>
                  <ul className="mt-10">
                      <li>
                          <div className="flex">
                              <div className="flex-shrink-0">
                                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                                      <svg width="20" height="20" fill="currentColor" className="h-6 w-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                                          </path>
                                      </svg>
                                  </div>
                              </div>
                              <div className="ml-4">
                                  <h5 className="text-lg leading-6 text-gray-900 dark:text-white font-bold">
                                      Digitaler Brillenpass.
                                  </h5>
                                  <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                                      Digitalisieren Sie Ihre optischen Daten und sparen Sie Zeit bei Ihrem nächsten Optikerbesuch
                                  </p>
                              </div>
                          </div>
                      </li>
                      <li className="mt-10">
                          <div className="flex">
                              <div className="flex-shrink-0">
                                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                                      <svg width="20" height="20" fill="currentColor" className="h-6 w-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                                          </path>
                                      </svg>
                                  </div>
                              </div>
                              <div className="ml-4">
                                  <h5 className="text-lg leading-6 text-gray-900 dark:text-white font-bold">
                                      Ihre Bedarfsanalyse.
                                  </h5>
                                  <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                                      Mit einer individuellen Bedarfsanalyse werden Ihre optischen Bedürfnisse ermittelt.
                                  </p>
                              </div>
                          </div>
                      </li>
                      <li className="mt-10">
                          <div className="flex">
                              <div className="flex-shrink-0">
                                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                                      <svg width="20" height="20" fill="currentColor" className="h-6 w-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                                          </path>
                                      </svg>
                                  </div>
                              </div>
                              <div className="ml-4">
                                  <h5 className="text-lg leading-6 text-gray-900 dark:text-white font-bold">
                                      Optikersuche.
                                  </h5>
                                  <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                                  Für Ihr optisches Anliegen finden Sie über ViSpecs ganz einfach einen Optiker in Ihrer Umgebung.
                                  </p>
                              </div>
                          </div>
                      </li>
                  </ul>
              </div>
              <div className="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1 px-40 pt-5 hidden lg:block">
                  <img src={GuyWithGlasses} alt="illustration" className="relative mx-auto shadow-lg rounded w-auto max-w-sm"/>
              </div>
          </div>
      </div>
    </section>
  );
}

export default InfoSection;