import React from "react";
import Container from "../container";

import PersonImage from "../../images/person1.jpg";
import PersonImage2 from "../../images/person2.jpg";

const TeamSection = () => {
  return (
    <section id="team" className="pt-12 lg:pt-20 pb-8 lg:pb-16">
      <h2 className="text-center text-light font-semibold tracking-wide uppercase mb-5">
          Über uns
      </h2>
      <Container>
        <p className="text-4xl lg:text-4xl xl:text-5xl font-bold dark:text-white text-center">
            Wir stellen uns vor.
        </p>
        <p className="text-center mb-12 text-xl font-normal text-gray-500 dark:text-gray-200 mt-3">
            Das ViSpecs-Team
        </p>
        <div className="flex items-center flex-col md:flex-row justify-evenly">
            <div className="p-4 pb-10 md:pb-4">
                <div className="text-center mb-4 opacity-90">
                    <a href="#" className="block relative">
                        <img alt="profil" src={PersonImage} className="mx-auto object-cover rounded-full h-40 w-40 "/>
                    </a>
                </div>
                <div className="text-center">
                    <p className="text-2xl text-gray-800 dark:text-white">
                        Patrick Schlager
                    </p>
                    <p className="text-xl text-gray-500 dark:text-gray-200 font-light">
                        Geschäftsführer
                    </p>
                    <p className="text-md text-gray-500 dark:text-gray-400 max-w-xs py-4 font-light italic">
                        "Text über Patrick Schlager"
                    </p>
                </div>
                <div className="pt-8 flex items-center justify-center border-t border-gray-200 w-44 mx-auto text-gray-500">
                    <a className="px-3" href="#">
                        <svg width="30" height="30" fill="currentColor" className="text-xl text-light hover:text-primary dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                            </path>
                        </svg>
                    </a>
                    <a className="px-3" href="#">
                        <svg width="30" height="30" fill="currentColor" className="text-xl text-light hover:text-primary dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                            </path>
                        </svg>
                    </a>
                </div>
            </div>
            <div className="p-4">
                <div className="text-center mb-4 opacity-90">
                    <a href="#" className="block relative">
                        <img alt="profil" src={PersonImage2} className="mx-auto object-cover rounded-full h-40 w-40 "/>
                    </a>
                </div>
                <div className="text-center">
                    <p className="text-2xl text-gray-800 dark:text-white">
                        Christoph Pichler
                    </p>
                    <p className="text-xl text-gray-500 dark:text-gray-200 font-light">
                        Geschäftsführer
                    </p>
                    <p className="text-md text-gray-500 dark:text-gray-400 max-w-xs py-4 font-light italic">
                        "Text über Christoph Pichler"
                    </p>
                </div>
                <div className="pt-8 flex items-center justify-center border-t border-gray-200 w-44 mx-auto text-gray-500">
                    <a className="px-3" href="#">
                        <svg width="30" height="30" fill="currentColor" className="text-xl text-light hover:text-primary dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                            </path>
                        </svg>
                    </a>
                    <a className="px-3" href="#">
                        <svg width="30" height="30" fill="currentColor" className="text-xl text-light hover:text-primary dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                            </path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
      </Container>
  </section>
  );
}

export default TeamSection;