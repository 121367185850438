import React from "react";

const CtaSection = () => {
  return ( 
    <section className="bg-gradient-to-l from-primary to-light">
        <div className="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
            <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
                <span className="block text-white">
                    Haben wir Ihr Interesse geweckt?
                </span>
                <span className="block text-white text-2xl mt-1">
                    Melden Sie sich jetzt an.
                </span>
            </h2>
            <p className="text-xl mt-4 max-w-md mx-auto text-white opacity-90">
                Über zehntausend Optiker vertrauen bereits auf digitale Lösungen. Werden auch Sie Teil davon.
            </p>
            <div className="lg:mt-0 lg:flex-shrink-0">
                <div className="mt-12 inline-flex rounded-md shadow">
                    <button type="button" className="py-4 px-6 bg-white bg-opacity-30 hover:bg-opacity-20 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow focus:outline-none rounded-lg">
                        Jetzt anmelden
                    </button>
                </div>
            </div>
        </div>
    </section>
  );
}

export default CtaSection;