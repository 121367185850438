import React from "react"

import SEO from "../components/seo";
import BaseLayout from "../components/layout/baseLayout";

import HeroSection from "../components/sections/heroSection";
import InfoSection from "../components/sections/infoSection";
import CtaSection from "../components/sections/ctaSection";
import TeamSection from "../components/sections/teamSection";
import ContactSection from "../components/sections/contactSection";

const IndexPage = () => (
  <div className="bg-gray-100">
    <SEO title="Home" />
    <BaseLayout>
      <HeroSection />
      <InfoSection />
      <CtaSection />
      <TeamSection />
      <ContactSection />
    </BaseLayout>
  </div>
)

export default IndexPage
